import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import { login, changePassword } from "../../apis/authApi";

import BackImage from "../../assets/Sacher_Solutions.png";

const Auth = () => {
  const navigate = useNavigate();

  const [status, setStatus] = useState("Login");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const chagngeStatus = () => {
    if (status === "Login") {
      setStatus("ChangePassword");
      setPassword("");
    }
    if (status === "ChangePassword") {
      setStatus("Login");
      setPassword("");
      setOldPassword("");
    }
  };

  const onSubmit = async () => {
    if (status === "Login") {
      if (password) {
        const response = await login(password);
        if (response?.accessToken) {
          localStorage.setItem("accessToken", response.accessToken);
          navigate("/dashboard");
        }
      }
    } else {
      if (oldPassword && password) {
        const response = await changePassword(oldPassword, password);
        if (response === "Success") {
          setOldPassword("");
          setPassword("");
          setStatus("Login");
        }
      }
    }
  };

  return (
    <MDBValidation style={{ backgroundColor: "#293d47" }}>
      <MDBContainer className="gradient-form d-flex align-items-center justify-content-center ">
        <MDBRow
          className="bg-white"
          style={{ height: "80%", minHeight: "660px" }}
        >
          <MDBCol className="p-0 col-md-6 col-12">
            <div className="d-flex flex-column m-5">
              <div className="text-center">
                <img src={BackImage} style={{ width: "185px" }} alt="logo" />
                <h3 className="mt-1 mb-5 pb-1">We are Sacher Solutions</h3>
              </div>

              {status === "Login" ? null : (
                <MDBValidationItem
                  feedback="Please input the old password."
                  invalid
                >
                  <MDBInput
                    value={oldPassword}
                    wrapperClass="mb-5"
                    label="Old Password"
                    id="old"
                    type="password"
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                  />
                </MDBValidationItem>
              )}

              <MDBValidationItem
                feedback="Please input the new password."
                invalid
              >
                <MDBInput
                  value={password}
                  wrapperClass="mb-4"
                  label={status === "Login" ? "Password" : "New Password"}
                  id="new"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </MDBValidationItem>
              <div className="text-center pt-1 mb-5 pb-1">
                <MDBBtn
                  className="mb-4 w-100 gradient-custom-2"
                  onClick={onSubmit}
                  type="submit"
                >
                  {status === "Login" ? "Continue" : "Change Password"}
                </MDBBtn>
                <div className="text-muted" onClick={chagngeStatus}>
                  {status === "Login" ? "Change Password" : "Login"}
                </div>
              </div>
            </div>
          </MDBCol>

          <MDBCol className="p-0 col-md-6 col-12">
            <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100">
              <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                <h3 className="mb-4">Meet our Logging Framework</h3>
                <p className="small mb-0">
                  Slog is a flexible and approachable logging system created and
                  upheld exclusively by Sacher Solutions. It streamlines the
                  development and testing journey, making bug fixing and
                  maintenance tasks as straightforward as possible.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBValidation>
  );
};

export default Auth;
