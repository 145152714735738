import React, { useState } from "react";

import { Modal, Form, DatePicker, Button, Upload, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { createLog } from "../../apis/logApi";

const NewLogModal = ({ isNewModalOpen, setIsNewModalOpen, setCurrentDate }) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const props = {
    beforeUpload(file) {
      setFile(file);
      return false;
    },
    file,
  };

  const handleCreate = async () => {
    form
      .validateFields()
      .then(async (data) => {
        form.resetFields();
        await createLog(data, file);
        setIsNewModalOpen(false);
        setFile(null);
        setCurrentDate(new Date());
      })
      .catch((info) => {
        setFile(null);
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="New Log"
      open={isNewModalOpen}
      okText="Ok"
      onOk={handleCreate}
      onCancel={() => setIsNewModalOpen(false)}
      width={600}
    >
      <Form name="form" {...formItemLayout} variant="filled" form={form}>
        <Form.Item
          label="Project ID"
          name="project_id"
          rules={[{ required: true, message: "Please input your Project ID!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Device Type"
          name="device_type"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="OS"
          name="os"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Processor Type"
          name="processor_type"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Date"
          name="date"
          rules={[{ required: true, message: "Please input!" }]}
        >
          <DatePicker
            format={"YYYY-MM-DDTHH:mm:ss"}
            showTime={true}
            needConfirm={false}
          />
        </Form.Item>
      </Form>

      <Form.Item
        wrapperCol={{
          xs: { span: 24, offset: 0 },
          sm: { span: 12, offset: 6 },
        }}
      >
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Form.Item>
    </Modal>
  );
};

export default NewLogModal;
