import { RiCheckboxCircleFill, RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";

const generateAlertType = (type, message) => {
  switch (type) {
    case "success":
      return toast.success(message, {
        icon: (
          <RiCheckboxCircleFill
            style={{
              width: "24px",
              height: "24px",
              color: "#22c55e",
            }}
          />
        ),
      });
    case "error":
      return toast.error(message, {
        icon: (
          <RiErrorWarningFill
            style={{
              width: "24px",
              height: "24px",
              color: "#ef4444",
            }}
          />
        ),
      });
    default:
      return toast(message);
  }
};

const Alert = ({ message, type }) => <>{generateAlertType(type, message)}</>;

export default Alert;
