import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Auth from "./auth/Auth";
import Dashboard from "./dashboard/Dashboard";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Auth />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
