import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import Alert from "../components/Alert/Alert";

export const getLogs = async (
  page,
  limit,
  search,
  projectId,
  sortOrder,
  setLoading
) => {
  try {
    setAuthToken(localStorage.getItem("accessToken"));

    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_API}/logs?page=${page}&limit=${limit}&search=${search}&sortOrder=${sortOrder}&project=${projectId}`
    );
    setLoading(false);

    return res.data.data;
  } catch (error) {
    setLoading(false);

    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    } else {
      Alert({ type: "error", message: "Server Error!" });
    }
  }
};

export const createLog = async (data, file) => {
  try {
    const formData = new FormData();
    formData.append("project_id", data.project_id);
    formData.append("device_type", data.device_type);
    formData.append("os", data.os);
    formData.append("processor_type", data.processor_type);
    formData.append("date", data.date);
    formData.append("file", file);

    const res = await axios.post(`${process.env.REACT_APP_API}/logs`, formData);

    return res.data.data;
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    } else {
      Alert({ type: "error", message: "Server Error!" });
    }
  }
};

export const deleteLog = async (id, setLoading) => {
  try {
    setAuthToken(localStorage.getItem("accessToken"));

    setLoading(true);
    const res = await axios.delete(`${process.env.REACT_APP_API}/logs/${id}`);
    setLoading(false);

    return res.data.data;
  } catch (error) {
    setLoading(false);

    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    } else {
      Alert({ type: "error", message: "Server Error!" });
    }
  }
};

export const downloadLog = async (fileName) => {
  try {
    setAuthToken(localStorage.getItem("accessToken"));

    const res = await axios.get(
      `${process.env.REACT_APP_API}/logs/download/${fileName}`
    );

    const url = window.URL.createObjectURL(
      new Blob([res.data], {
        type: "text/plain",
        encoding: "UTF-8",
      })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    } else {
      Alert({ type: "error", message: "Server Error!" });
    }
  }
};
