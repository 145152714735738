import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DownloadOutlined,
  PlusOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import {
  Space,
  Table,
  Spin,
  Button,
  Input,
  Popconfirm,
  Modal,
  FloatButton,
  Select,
} from "antd";

import { getLogs, deleteLog, downloadLog } from "../../apis/logApi";
import Alert from "../../components/Alert/Alert";
import NewLogModal from "./NewLogModal";

const { Search } = Input;

const Dashboard = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [pageObj, setPageObj] = useState({ page: 1, limit: 5 });
  const [sortOrder, setSortOrder] = useState("");
  const [projectId, setProjectId] = useState("all");
  const [projectOptions, setProjectOptions] = useState([
    { label: "All Projects", value: "all" },
  ]);
  const [loading, setLoading] = useState(false);
  const [isContentModalOpen, setIsContentModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [logOutModal, setLogOutModal] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      const getData = async () => {
        const response = await getLogs(
          pageObj.page - 1,
          pageObj.limit,
          search,
          projectId,
          sortOrder,
          setLoading
        );
        if (response) {
          setTotal(response.total);
          setData(response.result);
          let options = response.projectIds?.map((item) => ({
            value: item,
            label: item,
          }));

          setProjectOptions([
            { label: "All Projects", value: "all" },
            ...options,
          ]);
        }
      };

      getData();
    }
  }, [search, pageObj, projectId, sortOrder, currentDate]);

  const handleOk = async () => {
    setLogOutModal(false);
    localStorage.removeItem("accessToken");
    navigate("/");
  };

  const handleCancel = async () => {
    setLogOutModal(false);
  };

  const handleChange = async (page, pageSize) => {
    setPageObj((oldState) => ({
      ...oldState,
      page: page,
      limit: pageSize,
    }));
  };

  const handleSearch = async (value) => {
    setSearch(value);
  };

  const onView = (content) => {
    setIsContentModalOpen(true);
    console.log(content);
    setModalContent(content);
  };

  const onDelete = async (id) => {
    try {
      await deleteLog(id, setLoading);
      setCurrentDate(new Date());
      Alert({ type: "success", message: "Delete Success!" });
    } catch (error) {
      Alert({ type: "error", message: "Delete Failure!" });
    }
  };

  const handleRow = (record) => {
    return {
      onDoubleClick: (event) => {
        onView(record.content);
      },
    };
  };

  const columns = [
    {
      title: "No",
      key: "Number",
      dataIndex: "index",
      width: "2%",
      render: (text) => <center>{text}</center>,
    },
    {
      title: "Project ID",
      key: "project_id",
      dataIndex: "project_id",
      width: "30%",
    },
    {
      title: "Device",
      key: "device_type",
      dataIndex: "device_type",
    },
    {
      title: "OS",
      key: "os",
      dataIndex: "os",
    },
    {
      title: "Processor",
      key: "processor_type",
      dataIndex: "processor_type",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      sorter: true,
      render: (text) => {
        return new Date(text).toISOString();
      },
    },
    {
      title: "Actions",
      key: "action",
      width: "10%",
      render: (_, record) => (
        <center>
          <Space size="middle">
            <Button
              type="primary"
              onClick={() => onView(record.content)}
              style={{ width: "72px" }}
            >
              View
            </Button>
            <Popconfirm
              title="Delete Log"
              description="Are you sure to delete this log?"
              onConfirm={() => onDelete(record._id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" danger style={{ width: "72px" }}>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        </center>
      ),
    },
    {
      title: "File",
      key: "file",
      dataIndex: "file_name",
      render: (text) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() => downloadLog(text)}
          >
            {text}
          </Button>
        </Space>
      ),
    },
  ];

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="p-4">
      <div className="d-flex mb-4 justify-content-between">
        <div>
          <div className="mb-2">
            <Search placeholder="Search" onSearch={handleSearch} enterButton />
          </div>
          <div>
            <Select
              showSearch
              placeholder="Select a Project"
              optionFilterProp="children"
              onChange={(value) => setProjectId(value)}
              filterOption={filterOption}
              options={projectOptions}
              style={{ width: "250px" }}
            />
          </div>
        </div>
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsNewModalOpen(true)}
          >
            Add New Log
          </Button>
        </div>
      </div>

      <Spin spinning={loading}>
        <Table
          bordered
          columns={columns}
          dataSource={data.map((row, index) => ({
            ...row,
            index: index + 1 + (pageObj.page - 1) * pageObj.limit,
          }))}
          scroll={true}
          pagination={{
            position: "bottomRight",
            pageSize: pageObj.limit,
            pageSizeOptions: [5, 10, 20, 50],
            total: total,
            current: pageObj.page,
            onChange: (page, pageSize) => {
              handleChange(page, pageSize);
            },
          }}
          onRow={(record) => handleRow(record)}
          rowKey={(record) => record._id}
          onChange={(pagination, filters, sorter) => {
            if (sorter.order) {
              setSortOrder(sorter.order);
            } else {
              setSortOrder("");
            }
          }}
        />
      </Spin>

      <Modal
        title="Log"
        open={isContentModalOpen}
        onOk={() => setIsContentModalOpen(false)}
        onCancel={() => setIsContentModalOpen(false)}
        width={600}
      >
        <pre>{modalContent}</pre>
      </Modal>
      <NewLogModal
        isNewModalOpen={isNewModalOpen}
        setIsNewModalOpen={setIsNewModalOpen}
        setCurrentDate={setCurrentDate}
      />
      <FloatButton
        icon={<PoweroffOutlined />}
        type="primary"
        style={{
          right: 24,
        }}
        onClick={() => setLogOutModal(true)}
      />
      <Modal
        open={logOutModal}
        title="Logout"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <p>Do you want to Log out?</p>
      </Modal>
    </div>
  );
};

export default Dashboard;
