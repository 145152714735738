import axios from "axios";
import Alert from "../components/Alert/Alert";

export const login = async (password) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API}/auth/login`, {
      password,
    });

    Alert({ type: "success", message: "Welcome!" });
    return res.data.data;
  } catch (error) {
    switch (error.response.status) {
      case 401:
        Alert({ type: "error", message: "The Password is invaild!" });
        break;
      case 500:
        Alert({ type: "error", message: "Server Error!" });
        break;
      default:
        break;
    }
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API}/auth/change`, {
      oldPassword,
      newPassword,
    });

    Alert({ type: "success", message: "The Password is changed!" });
    return res.data.data;
  } catch (error) {
    switch (error.response.status) {
      case 501:
        Alert({ type: "error", message: "The Old Password is not correct!" });
        break;
      case 500:
        Alert({ type: "error", message: "Server Error!" });
        break;
      default:
        break;
    }
  }
};
